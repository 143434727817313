// App.js

import React from 'react';
import './App.css'; // Import CSS for styling

import logo from './logo.svg'; // Import your logo file
import LoginPage from './LoginPage.js'; // Import your Login Page component

function App() {
  return (
    <div className="App">
      {/* Logo Header */}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Cloud test app</h1>
      </header>

      {/* Login Page */}
      <LoginPage />
    </div>
  );
}

export default App;
